<template>
  <form class="search-form" method="GET" action="/" @submit.prevent="() => {}">
    <label for="search_field" class="is-sr-only">Sök</label>
    <div class="field">
      <div class="control">
        <input
          ref="input"
          :value="query"
          @input="doSearch"
          @keyup.enter="inputEnter"
          name="s"
          id="search_field"
          class="input search-form__input"
          :placeholder="$t('search.article_search_placeholder')"
          type="text"
          :data-canlosefocus="canLoseFocus"
        />
        <button :aria-label="$t('generic.search')" type="submit" class="icon search-form__button">
          <font-awesome-icon size="lg" :icon="['far', 'search']" />
        </button>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
  import { useHistoryStore } from '@/stores/history';
  import { useShortcutStore } from '@/stores/shortcut';
  import { mapState, mapActions } from 'pinia';
  import { PropType } from 'vue';

  export default {
    name: 'SearchInputBox',
    props: {
      query: {
        type: String as PropType<string>,
        default: '',
      },
      navigateOnEmpty: {
        type: Boolean as PropType<boolean>,
        default: true,
      },
    },
    data() {
      return {
        canLoseFocus: false
      }
    },
    computed: {
      ...mapState(useHistoryStore, ['previousPath']),
    },
    methods: {
      ...mapActions(useShortcutStore, ['registerShortcutHandlers', 'destroyShortcutHandlers']),
      doSearch(newQuery: string | Event) {
        if (typeof newQuery === 'object') {
          newQuery = (newQuery.target as HTMLInputElement).value;
        }

        if (this.navigateOnEmpty && newQuery === '') {
          this.$router.push(this.previousPath || '/mina-sidor');
          return;
        }

        if (this.$route.path === '/search') {
          this.$router.replace({ query: { q: newQuery } });
        } else {
          this.$router.push({ path: '/search', query: { q: newQuery } });
        }

        this.canLoseFocus = false
      },
      inputEnter() {
        this.canLoseFocus = true
        const quantityEl = (document.querySelector('.search-form-results tbody tr:first-child .col-quantity .input') as HTMLInputElement);
        if (quantityEl) {
          quantityEl.focus()
        }
      },
      escListner() {
        const input = this.$refs.input as any
        input.focus()
        input.select()
      }
    },
    mounted() {
      this.registerShortcutHandlers([
        { type: 'esc', handler: this.escListner }
      ])
    },
    unmounted() {
      this.destroyShortcutHandlers([
        { type: 'esc', handler: this.escListner }
      ])
    }
  };
</script>
