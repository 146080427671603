<template>
  <div>
    <header class="section header" role="banner">
      <div class="container relative">
        <div class="level is-mobile top-navigation-container">
          <nav class="level top-navigation is-hidden-touch">
            <LanguageSelector />
            <router-link
              class="level-item top-navigation__item is-size-8 tracking-wide has-text-dark is-uppercase"
              to="/calculation/wall"
            >
              {{ $t('generic.calculation_program') }}
            </router-link>
            <router-link
              class="level-item top-navigation__item is-size-8 tracking-wide has-text-dark is-uppercase"
              to="/prices"
            >
              {{ $t('generic.price_lists') }}
            </router-link>
            <router-link
              class="level-item top-navigation__item is-size-8 tracking-wide has-text-dark is-uppercase"
              to="/warehouse/arboga/"
            >
              {{ $t('warehouse.our_warehouses') }}
            </router-link>
            <a
              v-if="!status || !status.loggedIn"
              class="level-item top-navigation__item is-size-8 tracking-wide has-text-dark is-uppercase"
              href="/login"
            >
              {{ $t('generic.login') }}
            </a>
            <div v-click-outside="hideCustomerChooser" class="level-item relative" v-else>
              <a
                @click.prevent="visible.customerChooser = !visible.customerChooser"
                :class="[
                  'customer-chooser__toggle',
                  'top-navigation__item',
                  'is-size-8',
                  'tracking-wide',
                  'has-text-dark',
                  'is-uppercase',
                  visible.customerChooser ? 'open' : '',
                ]"
                id="widget_contact_picker_menu_item"
              >
                {{ name }}
                <font-awesome-icon :icon="['fas', 'caret-down']" />
              </a>
              <div aria-hidden="true" :class="['customer-chooser__dropdown', visible.customerChooser ? 'open' : '']">
                <TripleSelector
                  ref="tripleselector"
                  :run_on_mount="true"
                  v-if="isBendersEmployee"
                  @close="hideCustomerChooser"
                />
                <div
                  :class="{
                    'is-flex is-justify-content-space-between': user?.actingAsUser,
                    'level-right': !user?.actingAsUser,
                  }"
                >
                  <div v-if="user?.actingAsUser">
                    <a
                      name="benders_kundportal_action"
                      class="button is-link is-size-7 is-uppercase has-text-weight-medium tracking-wide m argin-xxsmall-top"
                      @click="
                        () => {
                          if (!loading.backToAdmin) {
                            returnToAdmin();
                            navigateAway('backToAdmin');
                          }
                        }
                      "
                      v-button-loader:dot-collision="loading.backToAdmin"
                      v-disabled="loading.backToAdmin"
                    >
                      {{ $t('generic.back_to_admin') }}
                    </a>
                  </div>
                  <div>
                    <a
                      class="button is-link is-size-7 is-uppercase has-text-weight-medium tracking-wide m argin-xxsmall-top"
                      @click="logout()"
                    >
                      {{ $t('generic.logout') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="user?.actingAsUser" class="level-item top-navigation__item">
              <a
                name="benders_kundportal_action"
                class="button top-navigation__button is-size-8 tracking-wide is-link is-uppercase"
                @click="
                  () => {
                    if (!loading.backToAdmin) {
                      returnToAdmin();
                      navigateAway('backToAdmin');
                    }
                  }
                "
                v-button-loader:dot-collision="loading.backToAdmin"
                v-disabled="loading.backToAdmin"
              >
                {{ $t('generic.back_to_admin') }}
              </a>
            </div> -->
            <div class="level-item top-navigation__item">
              <a
                :href="$t('generic.benders_url', localeByDivision)"
                target="_blank"
                class="button top-navigation__button is-size-8 tracking-wide is-light is-uppercase"
              >
                {{ $t('generic.to_benders_website') }}
              </a>
            </div>
          </nav>
          <div class="top-nav-left is-hidden-desktop">
            <a
              class="has-text-dark menu-icon is-hidden-desktop padding-none-right"
              @click="visible.mobileMenu = true"
              :aria-label="$t('generic.open_menu')"
            >
              <font-awesome-icon class="icon" size="lg" :icon="['far', 'bars']" />
            </a>
            <div
              v-if="usePostalcodeWarehouseLogic"
              v-click-outside="hidePostalcodeSelector"
              class="myaccount-dropdown-widget relative is-inline-block"
              :class="{ open: visible.postalcodeSelector }"
            >
              <a
                @click="visible.postalcodeSelector = !visible.postalcodeSelector"
                class="has-text-dark location-button is-hidden-desktop"
                :aria-label="$t('postalcode.open_menu')"
              >
                <font-awesome-icon class="icon" size="lg" :icon="['fas', 'map-marker-alt']" />
              </a>
              <div class="level-item absolute is-size-7" style="right: auto">
                <PostalcodeSelector />
              </div>
            </div>
          </div>
          <router-link to="/" :aria-label="$t('generic.go_to_front_page')" class="top-nav-mid is-narrow logo">
            <img src="@/assets/images/benders.svg" alt="Benders logotype" />
          </router-link>
          <div :class="['level', 'left-actions', !showAdminPanel ? 'no-admin-panel' : '']">
            <div v-if="showAdminPanel" class="is-hidden-touch" style="left: 11em; right: auto">
              <a
                @click="navigateAway('adminpanel')"
                class="button is-link admin-panel top-navigation__button is-size-8 tracking-wide is-uppercase"
                v-wp-href="'/wp/wp-admin/'"
                v-button-loader:dot-collision="loading.adminpanel"
                v-disabled="loading.adminpanel"
              >
                {{ $t('generic.adminpanel') }}
              </a>
            </div>
            <PostalcodeSelector v-if="usePostalcodeWarehouseLogic" class="is-hidden-touch margin-small-bottom" />
          </div>
          <div class="top-nav-right has-text-dark">
            <template v-if="status.PRODUCT_API_ENABLE">
              <SearchInputBox :navigateOnEmpty="true" :query="query" class="is-hidden-touch" />
              <router-link :to="`/search`" class="has-text-dark search-icon is-hidden-desktop padding-none-right">
                <font-awesome-icon class="icon" size="lg" :icon="['far', 'search']" />
              </router-link>
            </template>
            <div
              v-if="status.PRODUCT_API_ENABLE"
              v-click-outside="hideAccountDropdown"
              :class="['myaccount-dropdown-widget', 'relative', visible.accountDropdown ? 'open' : '']"
            >
              <a
                @click.prevent="visible.accountDropdown = !visible.accountDropdown"
                class="has-text-dark profile-button margin-none-right"
              >
                <font-awesome-icon class="icon" size="lg" :icon="['fas', 'user']" />
              </a>

              <div class="level-item absolute is-size-7">
                <AccountNavigation />
              </div>
            </div>
            <div
              v-if="status.PRODUCT_API_ENABLE && !$route.meta.hideCart"
              v-click-outside="hideCheckoutDropdown"
              :class="['checkout-dropdown-widget', 'relative', visible.checkoutDropdown ? 'open' : '']"
            >
              <a
                @click.prevent="toggleCart"
                class="has-text-dark bag-shopping"
                :aria-label="$t('generic.go_to_shopping_cart')"
              >
                <span class="tag is-danger is-rounded has-text-weight-semibold bag-shopping__tag">
                  {{ cart_total }}
                </span>
                <font-awesome-icon class="icon bag-shopping__icon" size="lg" :icon="['far', 'shopping-bag']" />
              </a>

              <div class="level-item absolute is-size-7 padding-xxxsmall-right">
                <CheckoutDropdown @close="hideCheckoutDropdown" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <nav
      role="navigation"
      v-click-inside="hideMobileMenu"
      :class="['secondary-navigation', visible.mobileMenu ? 'open' : null]"
    >
      <div class="is-hidden-desktop secondary-navigation__header section">
        <div class="container">
          <a class="has-text-dark menu-icon" @click="hideMobileMenu" :aria-label="$t('generic.close_menu')">
            <font-awesome-icon class="icon" size="lg" :icon="['far', 'times']" />
          </a>
        </div>
      </div>
      <div
        class="justify-center secondary-navigation__menu"
        :class="'product_cat_slug-' + ($route.params.category || $route.query.category)"
        style="min-height: 45px"
      >
        <ProductNavigation
          v-if="status.PRODUCT_API_ENABLE"
          class="container level justify-center is-block-touch margin-none-bottom"
        />
        <div v-else class="container level justify-end is-block-touch">
          <router-link
            :to="`/mina-sidor`"
            :class="[
              'level-item',
              'is-link',
              'is-narrow',
              'is-uppercase',
              'tracking-wide',
              'is-size-7',
              'has-text-white',
              'secondary-navigation__item',
              'secondary-navigation__item--my-account',
              $route.path.indexOf('/mina-sidor') !== -1 ? 'secondary-navigation__item--active' : null,
            ]"
          >
            {{ $t('generic.my_pages') }}
          </router-link>
        </div>
      </div>
      <div class="is-hidden-desktop secondary-navigation__sub-menu section">
        <div class="container">
          <router-link
            class="is-block is-size-7 leading-looser has-text-weight-semibold has-text-dark is-uppercase"
            to="/calculation/wall"
          >
            {{ $t('generic.calculation_program') }}
          </router-link>
          <router-link
            class="is-block is-size-7 leading-looser has-text-weight-semibold has-text-dark is-uppercase"
            to="/prices"
          >
            {{ $t('generic.price_lists') }}
          </router-link>
          <router-link
            class="is-block is-size-7 leading-looser has-text-weight-semibold has-text-dark is-uppercase"
            to="/warehouse/arboga/"
          >
            {{ $t('warehouse.our_warehouses') }}
          </router-link>
          <template v-if="!status.loggedIn">
            <router-link
              class="is-block is-size-7 leading-looser has-text-weight-semibold has-text-dark is-uppercase"
              to="/login"
            >
              {{ $t('generic.login') }}
            </router-link>
          </template>
          <template v-else>
            <router-link
              v-if="isBendersEmployee"
              :to="{ name: 'ActAsOtherUser' }"
              class="is-block is-size-7 leading-looser has-text-weight-semibold has-text-dark is-uppercase"
            >
              {{ $t('generic.act_as_user') }}
            </router-link>
            <a
              @click="logout()"
              class="is-block is-size-7 leading-looser has-text-weight-semibold has-text-dark is-uppercase"
            >
              {{ $t('generic.logout') }}
            </a>
          </template>
          <a
            v-if="showAdminPanel"
            class="is-block is-size-7 leading-looser has-text-weight-semibold has-text-dark is-uppercase"
            v-wp-href="'/wp/wp-admin/'"
          >
            {{ $t('generic.adminpanel') }}
          </a>
          <a
            class="is-block is-size-7 leading-looser has-text-weight-semibold has-text-dark is-uppercase"
            href="https://www.benders.se"
          >
            {{ $t('generic.to_benders_website') }}
          </a>
          <div v-if="user?.actingAsUser" class="level-item top-navigation__item">
            <button
              class="button top-navigation__button is-size-7 tracking-wide is-link is-uppercase"
              @click="returnToAdmin()"
            >
              {{ $t('generic.back_to_admin') }}
            </button>
          </div>
        </div>
        <LanguageSelector :always-visible="true" />
      </div>
    </nav>
    <nav role="navigation" class="profile-navigation">
      <div class="is-hidden-desktop profile-navigation__header section">
        <div class="container is-flex">
          <a class="has-text-dark menu-icon" :aria-label="$t('generic.close_menu')">
            <font-awesome-icon class="icon" size="lg" :icon="['far', 'times']" />
          </a>
        </div>
      </div>
      <div class="is-hidden-desktop justify-center profile-navigation__menu">
        <div class="container level justify-center is-block-touch">
          <AccountNavigation />
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
  import AccountNavigation from './AccountNavigation.vue';
  import ProductNavigation from './ProductNavigation.vue';
  import CheckoutDropdown from './CheckoutDropdown.vue';
  import SearchInputBox from './SearchInputBox.vue';
  import TripleSelector from '@/components/TripleSelector.vue';
  import { mapState, mapActions } from 'pinia';
  import PostalcodeSelector from '@/components/PostalcodeSelector.vue';
  import LanguageSelector from '@/components/LanguageSelector.vue';
  import { useCartStore } from '@/stores/cart';
  import { useAccountStore } from '@/stores/account';
  import { useTranslationStore } from '@/stores/translation';
  import { useShortcutStore } from '@/stores/shortcut';
  import { AxiosError } from 'axios';

  export default {
    name: 'HeaderPartial',
    components: {
      AccountNavigation,
      ProductNavigation,
      CheckoutDropdown,
      TripleSelector,
      SearchInputBox,
      PostalcodeSelector,
      LanguageSelector,
    },
    data() {
      return {
        loading: {
          adminpanel: false,
          backToAdmin: false,
        },
        visible: {
          mobileMenu: false,
          customerChooser: false,
          accountDropdown: false,
          checkoutDropdown: false,
          postalcodeSelector: false,
        },
        showAdminPanel: false,
      };
    },
    computed: {
      ...mapState(useAccountStore, ['status', 'user', 'isBendersEmployee']),
      ...mapState(useCartStore, {
        cart_total: 'total',
        usePostalcodeWarehouseLogic: 'usePostalcodeWarehouseLogic',
      }),
      ...mapState(useTranslationStore, ['localeByDivision']),
      name() {
        if (!this.user?.user) return '';

        var contactsText = '';
        if (Array.isArray(this.user?.contacts)) {
          if (this.user?.contacts.length == 1 && !!this.user?.contacts[0].customerNumber)
            contactsText = ` (${this.user?.contacts[0].customerNumber})`;
          if (this.user?.contacts.length > 1) contactsText = ` (${this.user?.contacts.length} st)`;
        }

        return this.user?.user.firstName + ' ' + this.user?.user.lastName + contactsText;
      },
      query() {
        const slug = typeof this.$route.query.q === 'string' ? this.$route.query.q : this.$route.query.q?.[0];
        return slug ?? '';
      },
    },
    methods: {
      ...mapActions(useAccountStore, ['login', 'logout', 'returnToAdmin', 'userCan']),
      ...mapActions(useShortcutStore, ['registerShortcutHandlers', 'destroyShortcutHandlers']),
      hideCustomerChooser() {
        this.visible.customerChooser = false;
      },
      hideAccountDropdown() {
        this.visible.accountDropdown = false;
      },
      hideCheckoutDropdown() {
        this.visible.checkoutDropdown = false;
      },
      hideMobileMenu() {
        this.visible.mobileMenu = false;
      },
      hidePostalcodeSelector() {
        this.visible.postalcodeSelector = false;
      },
      navigateAway(loader = '') {
        if (!loader) return;
        this.loading[loader] = true;
        let onNav = () => {
          this.loading[loader] = false;
          window.removeEventListener('visibilitychange', onNav);
        };
        window.addEventListener('visibilitychange', onNav);
      },
      toggleCart() {
        this.visible.checkoutDropdown = !this.visible.checkoutDropdown
      }
    },
    watch: {
      user: {
        deep: true,
        immediate: true,
        handler() {
          this.userCan('see_admin_bar')
            .then((showAdminPanel) => {
              this.showAdminPanel = showAdminPanel;
            })
            .catch((e) => {
              if ((e as AxiosError).response?.status === 401) {
                this.showAdminPanel = false;
              } else {
                console.error(e);
              }
            });
        },
      },
      visible: {
        deep: true,
        handler() {
          // iOS scroll fix
          if (this.visible.mobileMenu || this.visible.checkoutDropdown) {
            document.querySelector('html')?.classList.add('touch-scroll-fix');
            document.querySelector('body')?.classList.add('touch-scroll-fix');
          } else {
            document.querySelector('html')?.classList.remove('touch-scroll-fix');
            document.querySelector('body')?.classList.remove('touch-scroll-fix');
          }
        },
      },
    },
    mounted() {
      this.registerShortcutHandlers([
        { type: 'alt+s', handler: this.toggleCart }
      ]);
    },
    unmounted() {
      this.destroyShortcutHandlers([
        { type: 'alt+s', handler: this.toggleCart }
      ]);
    }
  };
</script>
