import { defineStore } from 'pinia';
import { _ } from 'lodash';

export interface ShortcutHandler {
  type: string;
  handler(event: KeyboardEvent): void;
}

export const useShortcutStore = defineStore('shortcut', {
  state: () => ({
    handlers: [] as ShortcutHandler[],
    shortcuts: [] as ShortcutHandler[],
    pressedKeys: {} as any
  }),
  actions: {
    initShortcuts(handlers?: ShortcutHandler[]) {
      this.shortcuts = [
        {
          type: 'esc',
          handler: (event) => {
            if (event.key === 'Escape') {
              this.trigger('esc', event);
              event.preventDefault();
            }
          }
        },
        {
          type: 'alt+s',
          handler: (event) => {
            //if (this.pressedKeys.Alt1 == true && this.pressedKeys.s0 == true) {
            if (event.altKey && event.key.toLowerCase() === 's') {
              this.trigger('alt+s', event);
              event.preventDefault();
            }
          }
        },
        {
          type: 'alt+c',
          handler: (event) => {
            if (event.altKey && event.key.toLowerCase() === 'c') {
              this.trigger('alt+c', event);
              event.preventDefault();
            }
          }
        }
      ];
      if (handlers) {
        this.registerShortcutHandlers(handlers);
      }
      document.addEventListener('keydown', this.keydown);
      document.addEventListener('keyup', this.keyup);
    },
    destroyShortcuts() {
      document.removeEventListener('keydown', this.keydown);
      document.removeEventListener('keyup', this.keyup);
      this.handlers = [];
    },
    registerShortcutHandlers(handlers: ShortcutHandler[]) {
      handlers.forEach((handler) => {
        if (!this.handlers.find((h) => _.isEqual(h, handler))) {
          this.handlers.push(handler);
        }
      })
    },
    destroyShortcutHandlers(handlers: ShortcutHandler[]) {
      handlers.forEach((handler) => {
        this.handlers = this.handlers.filter((h) => !_.isEqual(h, handler));
      })
    },
    keydown(event: KeyboardEvent) {
      this.pressedKeys[event.key + event.location] = true
      this.shortcuts.forEach((shortcut) => {
        shortcut.handler(event);
      })
    },
    keyup(event: KeyboardEvent) {
      this.pressedKeys[event.key + event.location] = false
    },
    trigger(type: string, event: KeyboardEvent) {
      const handlers = this.handlers.filter((handler) => handler.type === type);
      handlers.forEach((shortcut) => {
        shortcut.handler(event);
      });
    }
  }
});
